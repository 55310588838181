import {
  METRIC_TIME_FORMATS,
  METRIC_TYPES,
  MetricGraph,
  Metrics,
} from "@/types/common/metrics";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Chart from "./chart";
import { Select } from "@lula-technologies-inc/lux";

type ChartContainerProps = {
  metrics: Metrics | null;
};

export default function ChartContainer({ metrics }: ChartContainerProps) {
  const [charts, setCharts] = useState<MetricGraph[] | null>(null);
  const [timeFormat, setTimeFormat] = useState<METRIC_TIME_FORMATS>(
    METRIC_TIME_FORMATS.DAYS
  );

  useEffect(() => {
    if (!metrics) {
      setCharts(null);
      return;
    }

    setCharts([
      metrics[METRIC_TYPES.ACCOUNT].dateRanges[timeFormat],
      metrics[METRIC_TYPES.VEHICLES].dateRanges[timeFormat],
      metrics[METRIC_TYPES.CHARGE].dateRanges[timeFormat],
      metrics[METRIC_TYPES.APPLICATIONS_PENDING].dateRanges[timeFormat],
      metrics[METRIC_TYPES.FILES_UPLOADED].dateRanges[timeFormat],
    ]);
  }, [metrics, timeFormat]);

  return (
    <>
      <div
        className={clsx(
          "grid",
          "grid-cols-1",
          "md:grid-cols-2",
          "lg:grid-cols-4",
          "gap-2",
          "my-3"
        )}
      >
        <Select
          value={timeFormat}
          onChange={(v: { target: { value: METRIC_TIME_FORMATS } }) =>
            setTimeFormat(v.target.value as METRIC_TIME_FORMATS)
          }
          options={Object.entries(METRIC_TIME_FORMATS).map((entry) => {
            return {
              label:
                entry[1].substring(0, 1).toUpperCase() + entry[1].substring(1),
              value: entry[1],
            };
          })}
        />
      </div>
      <div
        className={clsx(
          "grid",
          "grid-cols-1",
          "lg:grid-cols-2",
          "gap-4",
          "my-3"
        )}
      >
        {charts &&
          charts.map((chart) => {
            return <Chart key={chart.title} data={chart} title={chart.title} />;
          })}
      </div>
    </>
  );
}
