import { ChartData } from "chart.js";
import clsx from "clsx";
import { Line } from "react-chartjs-2";

type ChartProps = {
  title: string;
  data: ChartData<"line">;
};

export default function Chart({ title, data }: ChartProps) {
  return (
    <div className={clsx("rounded-lg", "p-4", "relative", "bg-slate-50")}>
      <h4 className={clsx("text-xl", "mb-2")}>{title}</h4>
      <Line
        data={data}
        options={{
          hover: {
            mode: "index",
            intersect: false,
          },
        }}
      />
    </div>
  );
}
