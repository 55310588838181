import {
  CoverageType,
  METRIC_TYPES,
  MetricTotalMeasurementsForUi,
  MetricTypeLabel,
  Metrics,
  MetricsDateRange,
} from "@/types/common/metrics";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Tile from "./tile";
import { Select } from "@lula-technologies-inc/lux";

type TileContainerProps = {
  metrics: Metrics | null;
};

const DEFAULT_TOTALS: MetricTotalMeasurementsForUi = {
  current: {
    Year: 0,
    Quarter: 0,
    Month: 0,
    Week: 0,
    Day: 0,
  },
  previous: {
    Year: 0,
    Quarter: 0,
    Month: 0,
    Week: 0,
    Day: 0,
  },
};

export default function TileContainer({ metrics }: TileContainerProps) {
  const [metricType, setMetricType] = useState<METRIC_TYPES>(
    METRIC_TYPES.ACCOUNT
  );

  const [coverageTypes, setCoverageTypes] = useState<CoverageType[]>([
    CoverageType.ContinuousCoverage,
    CoverageType.ContinuousCoverageGBA,
    CoverageType.ORP,
    CoverageType.LulaSafe,
    CoverageType.GAIL,
  ]);

  const [totalMeasurements, setTotalMeasurements] =
    useState<MetricTotalMeasurementsForUi>(DEFAULT_TOTALS);

  useEffect(() => {
    if (!metrics || coverageTypes.length === 0) {
      setTotalMeasurements({
        current: { ...DEFAULT_TOTALS.current },
        previous: { ...DEFAULT_TOTALS.previous },
      });
      return;
    }

    const data = metrics[metricType].totals;

    let totals = {
      current: { ...DEFAULT_TOTALS.current },
      previous: { ...DEFAULT_TOTALS.previous },
    };

    coverageTypes.forEach((coverageType) => {
      totals.current.Day += data[coverageType].current.day.value;
      totals.current.Week += data[coverageType].current.week.value;
      totals.current.Month += data[coverageType].current.month.value;
      totals.current.Quarter += data[coverageType].current.quarter.value;
      totals.current.Year += data[coverageType].current.year.value;

      totals.previous.Day += data[coverageType].previous.day.value;
      totals.previous.Week += data[coverageType].previous.week.value;
      totals.previous.Month += data[coverageType].previous.month.value;
      totals.previous.Quarter += data[coverageType].previous.quarter.value;
      totals.previous.Year += data[coverageType].previous.year.value;
    });

    setTotalMeasurements(totals);
  }, [metrics, metricType, coverageTypes]);

  const handleCoverageTypeChange = (v: CoverageType) => {
    if (coverageTypes.includes(v)) {
      setCoverageTypes(coverageTypes.filter((c) => c !== v));
      return;
    }
    setCoverageTypes([...coverageTypes, v]);
  };

  return (
    <>
      <div
        className={clsx(
          "grid",
          "grid-cols-1",
          "md:grid-cols-2",
          "lg:grid-cols-3",
          "gap-2",
          "my-3"
        )}
      >
        <Select
          value={metricType}
          onChange={(v: { target: { value: METRIC_TYPES } }) =>
            setMetricType(v.target.value as METRIC_TYPES)
          }
          options={Object.entries(METRIC_TYPES).map((entry) => {
            return {
              label: MetricTypeLabel(entry[1]),
              value: entry[1],
            };
          })}
        />
        {/*<Select
          value={coverageTypes}
          onChange={(v) =>
            handleCoverageTypeChange(v.target.value as CoverageType)
          }
          options={Object.entries(CoverageType).map((entry) => {
            return {
              label: CoverageTypeLabel(entry[1]),
              value: entry[1],
            };
          })}
          multiple
        />*/}
      </div>
      <div
        className={clsx(
          "grid",
          "grid-cols-1",
          "md:grid-cols-3",
          "md:grid-rows-2",
          "xl:grid-cols-5",
          "xl:grid-rows-1",
          "gap-4",
          "xl:gap-3",
          "my-3"
        )}
      >
        {Object.entries(MetricsDateRange).map((entry) => {
          return (
            <Tile
              key={entry[0]}
              dateRange={entry[1]}
              currentValue={totalMeasurements.current[entry[1]]}
              previousValue={totalMeasurements.previous[entry[1]]}
            />
          );
        })}
      </div>
    </>
  );
}
