export const METRIC_KEY = "MET::";
export const METRIC_REPLACE_KEY = "?";
export const METRIC_CACHE_KEY = `${METRIC_KEY}DASHBOARD`;

export enum METRIC_TYPES {
  ACCOUNT = "account",
  CHARGE = "charge",
  VEHICLES = "vehicles",
  //ONBOARD_TIME = "onboardTime",
  FILES_UPLOADED = "filesUploaded",
  APPLICATIONS_PENDING = "applicationsPending",
}

export enum METRIC_TIME_FORMATS {
  WEEKS = "weeks",
  DAYS = "days",
}

export enum CoverageType {
  ContinuousCoverage = "Continuous_Coverage",
  ContinuousCoverageGBA = "Continuous_Coverage_GBA",
  ORP = "ORP",
  LulaSafe = "LulaSafe",
  GAIL = "GAIL",
}

export const WEEK_COUNT = 52;
export const DAY_COUNT = 60;

export type MetricNumericValue = {
  value: number;
  key?: string;
};

export type MetricGraphDataset = {
  label: string;
  data: number[];
  coverageType: CoverageType | "all";
};

export type MetricGraph = {
  title: string;
  labels: string[];
  keys?: string[];
  datasets: MetricGraphDataset[];
};

export type MetricTotals = {
  year: MetricNumericValue;
  quarter: MetricNumericValue;
  month: MetricNumericValue;
  week: MetricNumericValue;
  day: MetricNumericValue;
};

export type MetricTotalMeasurements = {
  current: MetricTotals;
  previous: MetricTotals;
};

export type MetricTotalMeasurementsByCoverageType = {
  [CoverageType.ContinuousCoverage]: MetricTotalMeasurements;
  [CoverageType.ContinuousCoverageGBA]: MetricTotalMeasurements;
  [CoverageType.ORP]: MetricTotalMeasurements;
  [CoverageType.LulaSafe]: MetricTotalMeasurements;
  [CoverageType.GAIL]: MetricTotalMeasurements;
};

export type MetricDateRangeMeasurements = {
  weeks: MetricGraph; //last 52 weeks
  days: MetricGraph; //last 30 days
};

export type MetricData = {
  totals: MetricTotalMeasurementsByCoverageType;
  dateRanges: MetricDateRangeMeasurements;
};

export type Metrics = {
  [METRIC_TYPES.ACCOUNT]: MetricData;
  [METRIC_TYPES.CHARGE]: MetricData;
  [METRIC_TYPES.VEHICLES]: MetricData;
  /*[METRIC_TYPES.ONBOARD_TIME]: MetricData;*/
  [METRIC_TYPES.FILES_UPLOADED]: MetricData;
  [METRIC_TYPES.APPLICATIONS_PENDING]: MetricData;
};

export enum MetricsDateRange {
  year = "Year",
  quarter = "Quarter",
  month = "Month",
  week = "Week",
  day = "Day",
}

export type MetricTotalMeasurementsForUi = {
  current: {
    Year: number;
    Quarter: number;
    Month: number;
    Week: number;
    Day: number;
  };
  previous: {
    Year: number;
    Quarter: number;
    Month: number;
    Week: number;
    Day: number;
  };
};

export function SimplifyNumber(num: number): string {
  if (num >= 1000000000) {
    return `${(num / 1000000).toFixed(1)}B`;
  } else if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  } else {
    return num.toString();
  }
}

export function MetricTypeLabel(metricType: METRIC_TYPES): string {
  switch (metricType) {
    case METRIC_TYPES.ACCOUNT:
      return "Accounts Activated";
    case METRIC_TYPES.CHARGE:
      return "Purchases";
    case METRIC_TYPES.VEHICLES:
      return "Vehicles Added Through Self-Onboarding";
    /*case METRIC_TYPES.ONBOARD_TIME:
      return "Onboard Time (s)";*/
    case METRIC_TYPES.APPLICATIONS_PENDING:
      return "Applications Created";
    case METRIC_TYPES.FILES_UPLOADED:
      return "Files Uploaded Through Self-Onboarding";
  }
}

export function CoverageTypeLabel(coverageType: CoverageType | "all"): string {
  switch (coverageType) {
    case CoverageType.ContinuousCoverage:
      return "Cont Cov";
    case CoverageType.ContinuousCoverageGBA:
      return "CC - GBA";
    case CoverageType.ORP:
      return "ORP";
    case CoverageType.LulaSafe:
      return "LulaSafe";
    case CoverageType.GAIL:
      return "GAIL";
    case "all":
      return "All";
  }
}

export function CoverageTypeChartColors(coverageType: CoverageType | "all"): {
  borderColor: string;
} {
  switch (coverageType) {
    case CoverageType.ContinuousCoverage:
      return {
        borderColor: "#333354",
      };
    case CoverageType.ContinuousCoverageGBA:
      return {
        borderColor: "#FF0053",
      };
    case CoverageType.ORP:
      return {
        borderColor: "#838399",
      };
    case CoverageType.LulaSafe:
      return {
        borderColor: "#f0dd5b",
      };
    case CoverageType.GAIL:
      return {
        borderColor: "#f00",
      };
    case "all":
      return {
        borderColor: "#FF5701",
      };
  }
}
