"use client";
import { Container as LuxContainer, SIZES } from "@lula-technologies-inc/lux";

type ContainerProps = {
  children: string | JSX.Element | JSX.Element[];
  title?: string | JSX.Element | JSX.Element[];
  subTitle?: string | JSX.Element | JSX.Element[];
};

export default function Container({
  children,
  title,
  subTitle,
}: ContainerProps) {
  return (
    <LuxContainer size={SIZES.LG} hasPadding className="md:mt-4">
      {title && <h1 className="title">{title}</h1>}
      {subTitle && <p className="mb-4">{subTitle}</p>}
      {children}
    </LuxContainer>
  );
}
