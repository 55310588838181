"use client";

import { useEffect, useState } from "react";
import "../utils/common/charts";
import Container from "@/components/common/container";
import TileContainer from "@/components/dashboard/tileContainer";
import { Metrics } from "@/types/common/metrics";
import ChartContainer from "@/components/dashboard/chartContainer";
import { ROUTE_LABELS, welcomeText } from "@/utils/common/translations";
import { GetLoginLocation } from "@/utils/common/login";
import { useRouter, useSearchParams } from "next/navigation";

const metricsEndpoint = "/api/common/metrics/dashboard";

export default function Home() {
  const router = useRouter();
  const params = useSearchParams();
  const [metrics, setMetrics] = useState<Metrics | null>(null);

  useEffect(() => {
    const location = GetLoginLocation();

    if (location) {
      router.push(location);
      return;
    }
  }, [params, router]);

  useEffect(() => {
    const fetchMetrics = async () => {
      const response = await fetch(metricsEndpoint);
      const data = await response.json();
      setMetrics(data);
    };

    fetchMetrics();
  }, []);

  return (
    <Container title={ROUTE_LABELS.DASHBOARD} subTitle={welcomeText}>
      <TileContainer metrics={metrics} />
      <hr />
      <ChartContainer metrics={metrics} />
    </Container>
  );
}
