import { MetricsDateRange, SimplifyNumber } from "@/types/common/metrics";
import clsx from "clsx";
import { useEffect, useState } from "react";

type TileProps = {
  dateRange: MetricsDateRange;
  currentValue: number;
  previousValue: number;
};

export default function Tile({
  dateRange,
  currentValue,
  previousValue,
}: TileProps) {
  const [values, setValues] = useState({
    current: "0",
    previous: "0",
  });

  useEffect(() => {
    setValues({
      current: SimplifyNumber(currentValue),
      previous: SimplifyNumber(previousValue),
    });
  }, [currentValue, previousValue]);

  return (
    <div className={clsx("rounded-xl", "p-4", "grid", "bg-slate-50")}>
      <div className={clsx("flex", "justify-between", "items-start", "gap-4")}>
        <h4 className={clsx("text-xl")}>{dateRange}</h4>
        <div
          className={clsx(
            "text-[1.75rem]",
            "leading-none",
            "font-mono",
            "font-thin"
          )}
        >
          {values.current}
        </div>
      </div>
      <div className={clsx("mt-2")}>
        <p className="text-xs">
          Prev. {dateRange}: {values.previous}
        </p>
      </div>
    </div>
  );
}
